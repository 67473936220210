import { render, staticRenderFns } from "./TheRestorePasswordContent.vue?vue&type=template&id=95e7a840&scoped=true&"
import script from "./TheRestorePasswordContent.vue?vue&type=script&lang=js&"
export * from "./TheRestorePasswordContent.vue?vue&type=script&lang=js&"
import style1 from "./TheRestorePasswordContent.vue?vue&type=style&index=1&id=95e7a840&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e7a840",
  null
  
)

export default component.exports