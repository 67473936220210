<template>
  <div id="content">
    <h1>
      Neues Passwort vergeben
    </h1>

    <div>
      Um sicher zu gehen, dass Sie sich nicht versehentlich vertippen, geben Sie Ihr gewünschtes Passwort bitte zweimal ein. Die farbige Anzeige unterhalb des ersten Feldes zeigt Ihnen an, wie sicher das eingegebene Passwort ist.
    </div>

    <div>
      Nach dem Abschicken werden Sie zurück auf forsa.omninet geleitet und können sich direkt mit Ihrem neuen Passwort anmelden.
    </div>

    <StatusMessage
      v-if="appStatus !== 1"
    />

    <AppPasswordInput
      type="password"
      v-model="newPassword"
      placeholder="Ihr neues Wunsch-Passwort"
    />

    <AppInput
      type="password"
      v-model="newPassword2"
      placeholder="Neues Wunsch-Passwort erneut eingeben"
      @enterPressed="submit"
    />

    <AppButton
      @click="submit"
      cls="material"
    >
      Absenden
    </AppButton>
  </div>
</template>


<script>
export default {

  name: 'ForgotPasswordContent',

  components: {
  },

  data(){
    return {
      newPassword: '',
      newPassword2: '',
    }
  },

  mounted(){

    this.verifyCode()
  },

  methods: {

    verifyCode(){

      this.appWorking();

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'validate-restore-password-code',
        data: {
          code: this.$route.params.code,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.clearAppStatus();
        }
      )
      .fail(
        resp => {
      
          this.appError('Der Link zur Vergabe eines neuen Passworts ist ungültig.', null);

          this.$router.push({name: 'portal'});
        }
      );
    },

    submit(){

      if (!this.newPassword.length) {

        this.appError('Bitte tragen Sie Ihr neues Wunsch-Passwort in das Eingabefeld ein.', null);

        return false;
      }

      if (this.newPassword !== this.newPassword2) {

        this.appError('Das neue Passwort stimmt nicht in beiden Eingabefeldern überein.', null);

        return false;
      }

      if (this.newPassword.length < 5) {

        this.appError('Das neue Passwort muss mindestens aus 5 Zeichen bestehen.', null);

        return false;
      }

      this.appWorking();
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-restore-password',
        data: {
          code: this.$route.params.code,
          password: this.newPassword,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihr neues Passwort wurde erfolgreich gespeichert. Sie können sich nun mit Ihrem neuen Passwort in forsa.omninet anmelden.');

          this.$router.push({name: 'portal'});
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Speichern des neuen Passworts ist ein Fehler aufgetreten.', null);
        }
      );
    },
  },
}
</script>


<style>
</style>


<style scoped>
#content > * {
  margin: 0 0 24px 0;
  hyphens: auto;
  max-width: 800px;
}
#content > h1 {
  margin-bottom: 48px;
}
</style>